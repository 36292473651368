<template>
  <div class="info" :class="{skeleton: !state.loaded }">
    <div class="title font-md">
      <b>{{ state.info.title }}</b>
    </div>
    <div class="desc font-sm" v-if="state.info.desc">
      <span>{{ state.info.desc }}</span>
    </div>
    <div class="content font-sm">
      <div v-html="state.info.content"></div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "modalInfo";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(async () => {
      store.commit("tuneModal", {component, size: "md"});

      state.loaded = false;
      import(`@/texts/${modalParams.category}.json`).then((data) => {
        state.loaded = true;
        state.info.title = data.title;
        state.info.desc = data.desc || "";
        state.info.content = data.content;
      });
    });

    const state = reactive({
      loaded: false,
      info: {
        title: "Wait a moment",
        desc: "Please wait a moment",
        content: `<p><span>Wait a moment</span></p><div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodoconsequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div><br/><p><span>Please Wait a moment.</span></p><p><span>Please Wait a moment. Thank you for waiting.</span></p><div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodoconsequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodoconsequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>`,
      }
    });

    const modalParams = store.getters.modalParams(component);

    return {component, state,};
  }
});
</script>

<style lang="scss" scoped>
.info {
  background: #fff;
  padding: $px25;

  .title {
    margin-bottom: $px9;
  }

  .content {
    padding-top: $px15;
  }

  &.skeleton::v-deep {
    .title > span, .desc > span {
      @include skeleton;
    }

    .content > div {
      p > span, div {
        @include skeleton;
      }
    }
  }
}
</style>